@import "Variables";

.newChatMessageForm {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    button {
        margin: 0px 15px;
    }
}

.chatImagePreviewsContainer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    min-height: 100px;
    align-items: center;
    max-height: 320px;
    overflow-y: scroll;
    img {
        margin: 5px 10px 5px;
        max-width: 150px;
        max-height: 150px;
        border: 1px solid gray;
    }
}

.chatImagePreview {
    position: relative;
    button {
        color: black;
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 10px;
        border-radius: 50%;
        padding: 4px 6px;
        border: 1px solid gray;
        background: lightgray;
    }
}

#imageUpload {
    display: none;
}
#imageUploadIcon {
    font-size: 25px;
    &:hover {
        cursor: pointer;
    }
}

#newChatMessageTextArea {
    height: 100px;
    width: 100%;
    font-size: 15px;
    box-sizing: border-box;
    margin: 15px 15px 15px 60px;
    background-color: #f5f5f5;
    resize: vertical;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen';
}

.typingStatus {
    margin-left: 60px;
    align-self: flex-start;
    margin-bottom: 10px;
}

#chatMessageList{
    height: 25vw;
    overflow-y: scroll;
}

.loadMoreMessages {
    line-height: 20px;
    width: 100%;
    border: none;
    cursor: pointer;
}

.alertMessage {
    background: #F5F5F5;
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid lightgray;
}

.chatActionButtons {
    margin-left: auto;
}

.chatErrorMessage {
    color: red;
    margin-left: 10px;
    font-size: 18px;
}

.secondaryButton {
    font-weight: bold;
    font-size: 14px;
    padding: 0 10px;
    height: 35px;
    border-radius: 0;
    margin-right: 5px;
    margin-top: 5px;
    background-color: #FFFFFF;
    color: #000000;
    border: 2px solid #000000;
    cursor: pointer;
    &:disabled {
        opacity: 0.6;
    }
}

.leaveChatButton {
    font-weight: bold;
    font-size: 14px;
    height: 35px;
    width: 100px;
    border-radius: 0;
    margin-right: 5px;
    background-color: #FFFFFF;
    color: #000000;
    border: 2px solid #000000;
    cursor: pointer;
}
