@import "Variables";

.customerSupportStatusSelector {
  position: relative;
  display: flex;
  flex-direction: column;
}

.customerSupportStatus:hover {
  cursor: pointer;
}

.supportStatusSelector {
  position: relative;
  display: flex;
  flex-direction: column;
}

.supportStatusOption {
  margin: 0;
  width: $drop-down-width;
  padding: 5px 0 5px 10px;
  font-size: 12px;
  border: 1px solid #000000;
  display: flex;
  background-color: #FFFFFF;
  height: $drop-down-height;
  align-items: center;
}

.supportStatusOption:hover {
  cursor: pointer;
}

#GoodSupportStatus {
  color: #000000;
  background-color: #FFFFFF;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#CheckSupportStatus, #CautionSupportStatus {
  color: #000000;
  background-color: $warning-yellow;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#FraudSupportStatus {
  color: #FFFFFF;
  background-color: $tekton-red;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
