@import "Variables";

#assigneeSelection {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.optionContainer {
  position: absolute;
  z-index: 2;
  top: $drop-down-height;
  background-color: #FFFFFF;
}

.assigneeOption {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: $drop-down-width;
  padding: 5px 0 5px 10px;
  font-size: 12px;
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid #000000;
  height: $drop-down-height;
}

.assigneeOption:focus {
  outline: none;
}

.assigneeOption:hover {
  cursor: pointer;
  color: $tekton-red;
}

@media only screen and (max-width: $max-mobile-width) {
  .assigneeOption {
    margin: 0;
  }
}