.senderIcon {
  position: absolute;
  left: 2px;
  z-index: 1;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.messageSenderIcon {
  top: -8px;
}