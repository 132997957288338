@import "Variables";

#chatList {
    position: relative;
    width: 20vw;
    border: 1px solid black;
    height: calc(100vh - 70px);
    overflow: auto;
    box-sizing: border-box;
    @media only screen and (max-width: $max-mobile-width) {
        width: 0;
    }
}

.chatListHeader {
    position: relative;
    h2 {
        margin: 5px;
        display: inline-block;
    }
    button {
        position: absolute;
        right: 0px;
        margin: 5px;
        top: 0px;
    }
}

.chatAvailable {
    border: 3px solid #00AF30;
    border-radius: 3px;
    background: lighten(#00AF30, 50%);
    cursor: pointer;
}
.chatUnavailable {
    border: 3px solid #c00;
    border-radius: 3px;
    background: lighten(#c00, 50%);
    cursor: pointer;
}

.loadMoreConversations {
    line-height: 20px;
    width: 100%;
    border: none;
    cursor: pointer;
}

.notificationBell {
    left: 0px;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    &:hover {
        color: $tekton-red;
    }
}
