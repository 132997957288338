@import "Variables";

.caseListItem {
  background-color: #f5f5f5;
  border-top: 1px solid #d7d7d7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  text-align: left;
  padding: 5px;
  height: 60px;
}

.caseListItem.selected {
  background-color: #ffffff;
  box-shadow: inset 5px 0 0 0px $tekton-red;
  padding-left: 10px;
}
.caseListItem.pulse {
  animation-name: pulseBackground;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes pulseBackground {
  0% {
    background-color: #f5f5f5;
  }
  50% {
    background-color: #dbe8ff;
  }
  100% {
    background-color: #f5f5f5;
  }
}

.caseListItem:hover {
  color: $tekton-red;
  background-color: #ffffff;
  cursor: pointer;
}

.caseListName,
.caseListDate {
  margin: 5px 5px 5px 60px;
}

.caseListDate {
  font-size: 12px;
}

.caseListIcons {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 5px;
  justify-content: space-between;
}

#caseListSenderIcon {
  position: absolute;
  margin: 0;
  left: 10px;
}

#selectedSenderIcon {
  position: absolute;
  margin: 0;
  left: 20px;
}

#toolsIcon {
  margin-left: 5px;
}
