@import "Variables";

#modalOverlay {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: lightgray;
  opacity: 0.5;
}


#newCaseModal {
    max-height: 70vh;
  position: fixed;
  z-index: 4;
  top: 20vh;
  left: 25vw;
  height: fit-content;
  width: 40vw;
  background-color: #FFFFFF;
  color: #000000;
  border: 2px solid #000000;
  text-align: left;
  overflow-y: auto;
  padding: 20px 40px 20px 40px;
}

#modalHeader {
  margin-left: 10px;
}

#closeModalButton {
  position: absolute;
  height: 40px;
  width: 40px;
  right: 20px;
}

#closeModalButton:hover {
  color: $tekton-red;
  cursor: pointer;
}

.newCaseSectionHeader {
  margin-bottom: 0;
  margin-left: 10px;
}

#submitNewCase {
  margin: 10px auto 10px 10px;
  border-radius: 0;
  display: block;
  float: right;
}

.sectionFields {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.sectionContainer {
    flex-basis: 100%;
    margin: 5px 10px 5px 10px;
}

.sectionField {
  border: 1px solid lightgray;
  width: 45%;
  margin: 5px 10px 5px 10px;
  padding: 0 5px 0 5px;
  background-color: #f5f5f5;
}

#replacementReasonModalContainer {
  margin: 10px 0 10px 10px;
}

.sectionField > input {
  background-color: #f5f5f5;
}

.fieldLabel {
  font-size: 12px;
  font-weight: lighter;
  margin: 0;
  visibility: hidden;
}

.fieldValue {
  border: 0;
  height: 30px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
}

.textFieldValue {
    width: 100%;
    height: 50px;
}

#submitButtonContainer {
  margin-right: 19px;
  box-sizing: border-box;
  font-weight: normal;
}

@media only screen and (max-width: $max-mobile-width) {

  #newCaseModal {
    width: 90vw;
    left: 5vw;
    padding: 10px 0 20px 0;
  }

  .sectionFields {
    margin: auto 5px auto 5px;
  }

  .sectionField {
    width: 85vw;
  }

  .fieldValue {
    margin-left: 0;
  }

  #submitButtonContainer {
    justify-content: center;
  }
}
