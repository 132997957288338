@import "Variables";

.editable-properties-container {
  border-top: 1px solid black;
}

#editableProperties {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px 5px 20px;
  position: relative;
  background-color: #EBEBEB;
  height: 50px;
}

#expandEditProperties {
  height: 20px;
  width: 20px;
}

#expandEditProperties:hover {
  color: $tekton-red;
  cursor: pointer;
}

#editableButtons {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10px;
}

.externalLink {
  color: $tekton-red;
}

#closeButton {
  height: 40px;
  box-sizing: border-box;
}


#orderPendingText {
  font-weight: bold;
  font-style: italic;
  padding:7px;
  //color: #f3790d;
  color: $tekton-red;
}

@media only screen and (max-width: 1400px) {
  #editableProperties {
    display: flex;
    justify-content: center;
    align-content: space-between;
    padding: 10px 5px 10px 5px;
    height: 140px;
  }

  #editableButtons {
    flex-direction: column;
    align-items: flex-end;
    width: fit-content;
  }
}

.reopened-case-warning {
  background-color: $warning-yellow;
  margin: 0;
  padding: 10px;
  font-weight: bold;
}