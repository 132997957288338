#emailTemplates {
    text-align: left;
    border: 1px solid gray;
    tr:nth-child(even) {
        background: #efefef;
    }
    td {
        textarea {
            width: 300px;
            height: 100px;
        }
        padding: 10px;
        border: 1px solid gray;
    }
    button:not(:last-child) {
        margin-right: 10px;
    }
}
