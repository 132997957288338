@import "Variables";

.caseProperties {
  border: 1px solid #D7D7D7;
  border-left: 0;
}
.casePropertiesContainer {
  position: relative;
}

#propertiesContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.casePropertiesHeader {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5px;
}

#propertiesSectionHeader {
  margin-left: 10px;
  font-size: 25px;
  font-weight: 700;
}

#propertiesContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-bottom: 10px;

  > #caseIssueSelector {
    margin: 0;
    padding-right: 5px;
    label {
      text-transform: capitalize;
      font-size: 1em;
    }
  }
}

.caseProperty {
  margin: 5px 0 5px 0;
  padding-right: 20px;
}

#caseButtonsContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  right: 10px;
}

#closeCaseButton {
  height: 35px;
  width: 75px;
  border-radius: 0;
}

#pendingUserResponseCaseButton {
  height: 35px;
  width: 100px;
  border-radius: 0;
  margin-right: 5px;
  background-color: #FFFFFF;
  color: #000000;
  border: 2px solid #000000;
}

#deleteButtonContainer {
  position: absolute;
  z-index: 2;
  bottom: 10px;
  right: 10px;
}

#deleteCaseButton {
  background-color: #FFFFFF;
  color: $tekton-red;
  border: 2px solid #000000;
  border-radius: 0;
}

#editPropertiesButton {
  height: 25px;
  width: 25px;
  color: gray;
  margin-left: 10px;
}

#editPropertiesButton:hover {
  cursor: pointer;
  color: $tekton-red;
}

#addressContainer {
  text-align: left;
  padding-bottom: 10px;
  padding-left: 10px;
}

#addressHeader {
  margin-top: 0;
  margin-bottom: 5px;
}

.addressDetails {
  padding: 0;
  margin: 0;
}

//Desktop only properties
@media only screen and (min-width: $max-mobile-width) {
  #propertiesContentContainer {
    flex-direction: row;
    padding-left: 45px;
  }

  #propertiesContent {
    height: 290px;
    padding-right: 30px;
    width: fit-content;
  }

  #addressContainer {
    height: 140px;
    padding-right: 10px;
    margin-right: 0;
  }
}

@media only screen and (min-width: 1400px) {
  #propertiesContent {
    height: 140px;
  }
}

//Mobile only properties
@media only screen and (max-width: $max-mobile-width) {
  #propertiesContent {
    padding-bottom: 50px;
    width: 100vw;
  }

  #addressContainer {
    margin-left: 10px;
    width: 100vw;
  }
}