@import "Variables";

body {
  /*This is shockingly necessary because iphones will otherwise */
  /*try to automatically adjust the font size*/
  /*if it thinks that the font is too small*/
  -webkit-text-size-adjust: 100%;
}

a {
  color: $tekton-red;
  text-decoration: none;
}


.app {
  text-align: center;
}

.appHeader {
  background-color: #000000;
  height: $header-height;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  position: relative;
  padding: $header-padding 0;
}

#envHeader {
  position: absolute;
  left: 20px;
  margin: 0;
  color: white;
}

#toolSolutionsHeader {
  color: white;
  text-align: left;
  padding-left: 10px;
  h1 {
    margin: 0;
  }
}

.appBody {
  display: flex;
  flex-direction: row;
}

.standardButton {
  background-color: $tekton-red;
  font-size: 14px;
  border-radius: 4px;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
  height: 45px;
  width: 150px;
  padding: 5px 10px 5px 10px;
  border: 0;
}

#errorMessagePopUp {
  position: fixed;
  z-index: 5;
  top: 25px;
  left: 25vw;
  width: 50vw;
  background-color: red;
  color: white;
  padding: 10px;
  border-radius: 4px;
  box-sizing: border-box;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.labelContainer > label {
  margin-right: 7px;
  font-size: 12px;
  font-weight: bold;
}

.dropDownContainer {
  position: relative;
}

#dropDownIcon {
  width: 30px;
  height: 15px;
}

#tektonLogo {
  height: 40px;

  @media (max-width: 1000px) {
    width: 60%;
  }
}

.liveChatMedia {
  max-width: 100%;
}

.tstSelect {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #000000;
    width: 200px;
    height: 35px;
    position: relative;
    select {
        position: absolute;
        width: 100%;
        padding-left: 10px;
        padding-right: 30px;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: none;
        background: none;
    }
}
