@import "Variables";

#caseIssueSelector {
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    text-transform: uppercase;
  }
}

#selectedIssueOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.caseIssueOption {
  margin: 0;
  width: 200px;
  padding: 5px 0 5px 10px;
  font-size: 12px;
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid #000000;
  height: $drop-down-height;
}

#replacementReasonContainer {
  top: $drop-down-height;
}

.caseIssueOption:hover {
  color: $tekton-red;
  cursor: pointer;
}
@media only screen and (max-width: 1300px) {
  #caseIssueSelector {
    margin: 10px;
  }
}
