@import "Variables";

.chatStatusIcon {
    &.active {
        color: green;
    }
    &.inactive {
        color: #c3c35a;
    }
    &.closed {
        color: maroon;
    }
    font-size: 14px;
}

.chatListName {
    margin: 5px 5px 0px 60px;
}

.chatListAssignee {
    .assigned {
        color: #c00;
    }
    font-size: 0.8rem;
    margin-left: 60px;
}
