@import "Variables";

.caseList {
  position: relative;
  width: 20vw;
  border: 1px solid black;
  height: calc(100vh - 70px);
  overflow: auto;
  box-sizing: border-box;
  min-width: 305px;
}

.caseListContainer {
  min-width: 305px;
}

#caseListHeader {
  margin: 5px;
}

#caseListFilters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 10px 5px 10px 5px;
}

#newCaseButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 0;
}

#newCaseIcon {
  width: 15px;
  height: 15px;
}

#closedCasesButton {
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 5px;
}

@media only screen and (max-width: $max-mobile-width) {
  .caseList {
    width: 100vw;
  }
}
