@import "Variables";

.caseMessageList {
  position: relative;
}

.newMessageContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-left: 5px;
  margin-bottom: 10px;
}

.messageButton {
  background-color: $tekton-red;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
  height: 40px;
  width: 170px;
  box-sizing: border-box;
  &:disabled {
      opacity: 0.6;
  }
}

#sendMessageInternal {
  background-color: #FFFFFF;
  color: #000000;
  border: 2px solid #000000;
}

#sendMessageExternal {
  color: #FFFFFF;
  background-color: $tekton-red;
  border: 0;
}

#warningIcon {
  margin-right: 3px;
}

#hiddenMessagesContainer {
  border: 1px solid #7D7D7D;
  border-left: 0;
  border-right: 0;
  height: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#hiddenMessagesCount {
  border: 1px solid #7D7D7D;
  background-color: #FFFFFF;
  color: #7D7D7D;
  margin: 0 0 0 10px;
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 40px;
  border-radius: 50%;
  z-index: 1;
}

#hiddenMessagesCount:hover {
  cursor: pointer;
  color: $tekton-red;
  background-color: #FFFFFF;
}

#newMessageTextArea {
  height: 100px;
  font-size: 15px;
  box-sizing: border-box;
  margin: 20px 10px 5px 50px;
  flex-basis: 100%;
  background-color: #f5f5f5;
  resize: vertical;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen';
}

.messageListHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5px;
}

#messageHeader {
  margin-left: 10px;
  font-size: 25px;
  font-weight: 700;
}

#messageListContent {
  padding-left: 40px;
  padding-right: 40px;
}

#draftStatusText {
    margin: 5px;
    margin-left: 50px;
    flex: 1;
    white-space: nowrap;
    text-align: left;
}


@media only screen and (max-width: $max-mobile-width) {
  #messageListContent {
    padding-left: 0;
    padding-right: 0;
  }
  #draftStatusText {
      text-align: center;
      margin-left: 5px;
  }
}

.sendMessageButtons {
    button, span {
        margin: 10px 15px 10px 15px;
    }
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
}

.fileName, .fileSize {
  display: inline;
}

#attachments {
  display: flex;
  flex-direction: column;
}

.individualAttachment:not(:first-of-type) {
  margin-top: 10px;
}