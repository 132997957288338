@import "Variables";

#selectedCaseContainer {
  width: 60vw;
  height: calc(100vh - #{$header-height + ($header-padding * 2)});
  display: flex;
  flex-direction: column;
}

#caseDetails {
  width: 60vw;
  overflow: auto;
}

.backToCaseList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 50px;
  background-color: #FFFFFF;
  color: #000000;
  text-align: left;
}

#collapseButton {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

#collapseButton:hover {
  cursor: pointer;
}

#backText {
  margin-left: 10px;
}

@media only screen and (max-width: $max-mobile-width) {
  #selectedCaseContainer {
    width: 100vw;
  }

  #caseDetails {
    width: 100vw;
    height: fit-content;
    overflow: visible;
    box-sizing: border-box;
    padding-bottom: 20px;
  }
}