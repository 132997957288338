@import "Variables";

#endUserInfo {
  margin-bottom: 30px;
}

.caseOrderForm {
  margin: 0 0 10px 20px;
}

.orderFormSectionTitle {
  font-weight: bold;
  font-size: 20px;
}

.caseOrderHeader {
  margin: 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5px;
}

#orderHeaderText {
  margin-left: 10px;
  font-size: 25px;
  font-weight: 700;
}

#submitButton {
  background-color: $tekton-red;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  height: 40px;
  width: 170px;
  border-width: 0;
}

.caseOrder {
  border-top: 1px solid #d7d7d7;
  border-left: 0;
}

.sectionContent {
  margin-top: 15px;
}

.itemImage {
  max-width: 125px;
}
.addRemoveItem {
  border: none;
  font-weight: bold;
  background-color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  height: 40px;
  width: 170px;
  padding: 5px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

#addItemIcon {
  border: 1px solid black;
  padding: 5px 6px;
}

#addItemText {
  padding: 5px 0 5px 10px;
  margin: 0;
}

.removeItem {
  font-weight: normal;
  color: #7d7d7d;
  text-decoration: underline;
}

#giveRewardsButton {
  margin: 15px;
  background-color: $tekton-red;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  height: 40px;
  width: 170px;
  border-width: 0;
}

.userDetails p {
  width: 100%;
}

#cancelOrder {
  border: none;
  background-color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  color: $tekton-red;
}

.itemNumber {
  color: #7d7d7d;
  font-size: 14px;
}

.orderFormSection {
  text-align: left;
}

.orderFormSection input {
  color: #000;
  outline: 0;
  padding: 5px 10px;
  width: 95%;
  background: transparent;
  border: none;
  display: inline-block;
  height: 28px;
  margin: 0;
  line-height: normal;
  font: inherit;
}
.userAddress select {
  color: #000;
  outline: 0;
  padding: 0px 5px;
  width: 100%;
  background: transparent;
  border: none;
  display: inline-block;
  height: 28px;
  margin-bottom: 10px;
  line-height: normal;
  font: inherit;
}

.unverifiedCustomer {
  color: #c00;
}

.addressSelector {
  display: flex;
  align-items: center;
}
.addressSelector > * {
  margin-right: 10px;
  margin-bottom: 5px;
}

.addressSelector button {
  height: auto;
  width: 100px;
  border-radius: 0;
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #000000;
}

#emailAddress {
  width: 100%;
}

.input-box {
  background: #f5f5f5;
  border: 1px solid #acacac;
  margin: 5px 10px 5px 0px;
  padding: 0;
}

.input-label {
  color: #7d7d7d;
  display: block;
  font-size: 12px;
  line-height: 14px;
  margin: 5px 10px 1px;
}

.itemDescriptionSection {
  padding: 0 10px 0 0;
}
.altItemsContainer {
  padding-bottom: 7px;
}
.altItemRow {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  color: #535353;
  /*padding: 5px 0 7px 0;*/
}

.altItemNumber {
  margin-bottom: 5px;
  margin-right: 10px;
  padding: 7px;
  border: none;
  border-radius: 5px;
  background-color: #ebebeb;
  color: #535353;
  font-weight: bold;
  font-size: 16px;
}

.altItemRow:last-child {
  margin-right: 0;
}

.altItemNumber:hover {
  cursor: pointer;
}

.itemDetailsRow {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.reason {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.reason p {
  font-weight: bold;
  font-size: 12px;
  margin-right: 7px;
}

.reason select {
  font-weight: normal;
  margin-top: 5px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /*width: 180px;*/
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  height: 35px;
  padding: 5px 0px 5px 10px;
  border-image: initial;
  border: 0;
  outline: 1px inset black;
  outline-offset: -1px;
}

.itemQuantity {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.itemQuantity p {
  font-weight: bold;
  font-size: 12px;
  margin-right: 7px;
}

.itemQuantity input {
  border: 1px solid black;
  font-weight: normal;
  width: 50px;
  margin-top: 5px;
  padding: 0px 5px;
}

.rewardsSection {
  border-top: 1px solid #d7d7d7;
  margin-top: 20px;
  padding: 20px;
}

.rewardsInput {
  background-color: #f5f5f5;
}

#existingRewards {
  display: flex;
  justify-content: flex-start;
  margin-left: 15px;
  text-align: left;
}

#existingRewards > p {
  padding: 0;
  margin-bottom: 0;
}

#newRewards {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 15px;
}

#newRewards > p {
  font-weight: bold;
  margin: 20px 10px 0 0;
}

#newRewards > input {
  border: 1px solid black;
  font-weight: normal;
  width: 50px;
  margin: 15px 0;
  padding: 0 5px;
}

.buttonsRow {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;
}

@media only screen and (min-width: 1000px) {
  .userAddressRow {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .userAddressRow div:last-child {
    margin-left: 10px;
  }

  .input-box {
    width: 100%;
  }

  .itemsRow {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .itemSection {
    width: 50%;
    padding-right: 12px;
  }

  #replacementItemsDetails {
    margin-left: 10px;
  }
}

.itemInventory {
    width: fit-content;

    > div {
        margin-top: 5px;
        color: $tekton-red;
    }
}

@media only screen and (max-width: 1000px) {
  #submitButton {
    margin-right: 10px;
  }
}
