@import "Variables";

.pendingResponseButton {
    background-color: #F5F5F5;
    color: #7D7D7D;
    height: 40px;
    width: 80px;
    font-weight: bold;
    margin: 10px 0 15px 0;
    border: 1px solid #7D7D7D;
    &.selected {
        background-color: $tekton-red;
        color: #FFFFFF;
        border: 1px solid $tekton-red;
    }
    &:first-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    &:last-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.pendingResponseButton:hover {
    cursor: pointer;
}

.pendingResponseButton:focus {
    outline: none;
}

.pendingResponseFilter {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
