#messageContainer {
  text-align: left;
}

#messageContainer:hover {
  cursor: pointer;
}

.individualMessage {
  padding: 5px;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  text-align: left;
  min-height: 55px;
}

.individualMessageContent {
  width: 70%;
  margin-bottom: 20px;
  margin-left: 60px;
}

#messageRecipient {
  font-size: 12px;
  margin-top: 0;
}

.flagContainer {
  margin: 0;
  height: 30px;
  color: black;
  position: absolute;
  right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.hasAttachmentsFlag {
  top: 15px;
  width: 50px;
}

.internalOnlyHeader {
  border: 2px solid #000000;
  color: #000000;
  border-radius: 20px;
  margin: 10px 0 0 0;
  text-wrap: none;
  font-size: 14px;
  font-weight: 900;
  width: fit-content;
  padding: 3px 5px 3px 5px;
}

.attachmentIcon {
  margin: 10px 5px 0 0;
}

.gmail_quote {
  display: none;
}

#messagePreviewName, #messageSender {
  width: fit-content;
  white-space: nowrap;
  margin-left: 60px;
  font-size: 15px;
  font-weight: bold;
}

#messageSender {
  margin-bottom: 0;
  margin-left: 0;
}

.messagePreviewText {
  box-sizing: border-box;
  font-size: 16px !important;
  text-align: left;
  padding-right: 10px;
  margin: 10px 50px 10px 60px;
  max-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expandedMessage {
  overflow-x: hidden;
}

.attachmentImage {
  width: 150px;
  height: auto;
  margin-bottom: 10px;
  background-color: #f5f5f5;
}

.attachmentChatImage {
  width: auto;
  height: 300px;
  margin-bottom: 10px;
  background-color: #f5f5f5;
}

.loadingChatImage {
    height: 300px;
    margin-bottom: 10px;
}

.senderAndTimeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 150px;
  position: relative;
}

#messagePreviewName, #timeSent {
  margin-top: 10px;
  margin-bottom: 0;
}

.messageText {
  width: fit-content;
}

#timeSent {
  font-size: 12px;
  margin-left: 15px;
  margin-right: 10px;
}

img[src*="cid:"] {
  display: none;
}

.tkn-loading {
    margin: 5px 5px;
    width: fit-content;
}

#tkn-loading-filters {
    margin-top: 0;
}

.tkn-loading-text {
    color: #999;
    display: inline-block;
    line-height: 40px;
}

.tkn-spinner {
    margin: 0 12px 0 0;
    width: 25px;
    height: 40px;
    text-align: center;
    font-size: 0;
    display: inline-block;
    vertical-align: middle;
}

.tkn-spinner > div {
    background-color: #cacaca;
    height: 100%;
    width: 2.5px;
    display: inline-block;
    margin: 0 1.25px;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.tkn-spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.tkn-spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.tkn-spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.tkn-spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4)
    }

    20% {
        -webkit-transform: scaleY(1.0)
    }
}

@keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }

    20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}
