@import "Variables";

#caseStatusSelector {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 0 !important;
}


#currentStatus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.statusOption {
  margin: 0;
  padding: 5px 0 5px 10px;
  font-size: 12px;
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid #000000;
  text-align: left;
  width: $drop-down-width;
  height: $drop-down-height;
}

.statusOption:hover {
  color: $tekton-red;
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  #caseStatusSelector {
    margin: 10px;
  }
}